var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("KeyValueCard2", {
        attrs: {
          item: _vm.detail,
          cols: _vm.filteredRows,
          title: "Round detail",
          loading: false,
          color: "secondary",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "heading",
              fn: function () {
                return [
                  _c(
                    "CardHeading",
                    { attrs: { title: "Round Detail" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "elevation-0",
                          attrs: {
                            fab: "",
                            small: "",
                            color: "primary darken-1",
                          },
                          on: {
                            click: function ($event) {
                              _vm.linkOptions = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-cog-play")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `item.device.value`,
              fn: function ({ item }) {
                return [
                  item.device === 0
                    ? _c("v-icon", [_vm._v("mdi-projector-screen-outline")])
                    : item.device === 1
                    ? _c("v-icon", [_vm._v("mdi-monitor")])
                    : item.device === 2
                    ? _c("v-icon", [_vm._v("mdi-cellphone")])
                    : item.device === 3
                    ? _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v(" mdi-tablet "),
                      ])
                    : _c("span", [_vm._v("unknown")]),
                ]
              },
            },
            {
              key: `item.bet.value`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("Number")(item.bet, { currency: _vm.currency })
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.win.value`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("Number")(item.win, { currency: _vm.currency })
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.netto.value`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("Number")(item.netto, { currency: _vm.currency })
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: `item.finished.value`,
              fn: function ({ item }) {
                return [
                  _c("v-icon", [
                    _vm._v(_vm._s(item.finished ? "mdi-check" : "mdi-close")),
                  ]),
                ]
              },
            },
            {
              key: `item.start_time.value`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("Date")(item.start_time)) + " "),
                ]
              },
            },
            {
              key: `item._orientation.value`,
              fn: function ({ item }) {
                return [
                  item._orientation === 1
                    ? _c("v-icon", [_vm._v(" mdi-cellphone mdi-rotate-90 ")])
                    : _c("v-icon", [_vm._v("mdi-cellphone")]),
                ]
              },
            },
            {
              key: `item.flags.value`,
              fn: function ({ item }) {
                return [
                  _vm._l(item.flags, function (flag) {
                    return [
                      _vm.ROUND_FLAGS[flag]
                        ? _c(
                            "v-tooltip",
                            {
                              key: flag,
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-avatar",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  size: "30",
                                                  color: "primary",
                                                },
                                              },
                                              "v-avatar",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm.ROUND_FLAGS[flag].icon
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold kajot-anti-text--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.ROUND_FLAGS[flag]
                                                            .icon
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold kajot-anti-text--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.ROUND_FLAGS[flag]
                                                            .title
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.ROUND_FLAGS[flag].tooltip)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ]
              },
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary darken-1" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("openDetail")
                        },
                      },
                    },
                    [_vm._v(" Open Round Detail ")]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.linkOptions,
            callback: function ($$v) {
              _vm.linkOptions = $$v
            },
            expression: "linkOptions",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 primary" }, [
                _vm._v("Replay Settings"),
              ]),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [
                  _c(
                    "div",
                    { staticClass: "mx-0 my-5 d-flex flex-row align-center" },
                    [
                      _c("span", { staticClass: "mr-4" }, [_vm._v("Custom:")]),
                      _c("v-switch", {
                        staticClass: "mt-n1",
                        attrs: { "hide-details": "", inset: "" },
                        model: {
                          value: _vm.replaySettings.advanced.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.replaySettings.advanced, "value", $$v)
                          },
                          expression: "replaySettings.advanced.value",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.replaySettings.advanced.value
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-space-between align-center",
                          },
                          [
                            _c("div", [_vm._v("Device:")]),
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mt-0",
                                attrs: { "hide-details": "", row: "" },
                                model: {
                                  value: _vm._device,
                                  callback: function ($$v) {
                                    _vm._device = $$v
                                  },
                                  expression: "_device",
                                },
                              },
                              [
                                _c("v-radio", {
                                  staticClass: "my-0",
                                  attrs: {
                                    "hide-details": "",
                                    value: 1,
                                    label: "Desktop",
                                    "on-icon": "mdi-monitor",
                                    "off-icon": "mdi-monitor",
                                  },
                                }),
                                _c("v-radio", {
                                  staticClass: "my-0",
                                  attrs: {
                                    "hide-details": "",
                                    value: 2,
                                    "on-icon": "mdi-cellphone",
                                    label: "Mobile",
                                    "off-icon": "mdi-cellphone",
                                  },
                                }),
                                _c("v-radio", {
                                  staticClass: "my-0",
                                  attrs: {
                                    "hide-details": "",
                                    value: 3,
                                    label: "Tablet",
                                    "on-icon": "mdi-tablet",
                                    "off-icon": "mdi-tablet",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-space-between align-center",
                          },
                          [
                            _c("div", [_vm._v("Orientation:")]),
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "" },
                                model: {
                                  value: _vm.replaySettings.orientation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.replaySettings,
                                      "orientation",
                                      $$v
                                    )
                                  },
                                  expression: "replaySettings.orientation",
                                },
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    value: 1,
                                    label: "Landscape",
                                    "on-icon": "mdi-arrow-expand-horizontal",
                                    "off-icon": "mdi-arrow-expand-horizontal",
                                  },
                                }),
                                _vm.replaySettings.device !== 1
                                  ? _c("v-radio", {
                                      attrs: {
                                        value: 2,
                                        label: "Portrait",
                                        "on-icon": "mdi-arrow-expand-vertical",
                                        "off-icon": "mdi-arrow-expand-vertical",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-4 d-flex flex-row justify-space-between align-center",
                          },
                          [
                            _c("div", [_vm._v("Resolution:")]),
                            _c("v-text-field", {
                              staticClass: "mx-2",
                              attrs: {
                                outlined: "",
                                dense: "",
                                type: "number",
                                "hide-details": "",
                                label: "Width",
                              },
                              model: {
                                value: _vm.replaySettings.advanced.width,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.replaySettings.advanced,
                                    "width",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "replaySettings.advanced.width",
                              },
                            }),
                            _c("v-text-field", {
                              staticClass: "mx-2",
                              attrs: {
                                outlined: "",
                                dense: "",
                                type: "number",
                                "hide-details": "",
                                label: "Height",
                              },
                              model: {
                                value: _vm.replaySettings.advanced.height,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.replaySettings.advanced,
                                    "height",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "replaySettings.advanced.height",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-space-between align-center",
                          },
                          [
                            _c("div", [_vm._v("Mode:")]),
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mt-0 mb-3",
                                attrs: { row: "", "hide-details": "" },
                                model: {
                                  value: _vm.replaySettings.advanced.device,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.replaySettings.advanced,
                                      "device",
                                      $$v
                                    )
                                  },
                                  expression: "replaySettings.advanced.device",
                                },
                              },
                              [
                                _c("v-radio", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    value: 1,
                                    label: "Desktop",
                                    "on-icon": "mdi-monitor",
                                    "off-icon": "mdi-monitor",
                                  },
                                }),
                                _c("v-radio", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    value: 2,
                                    "on-icon": "mdi-cellphone",
                                    label: "Mobile",
                                    "off-icon": "mdi-cellphone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-center" },
                    [
                      _c("span", { staticClass: "mr-3" }, [
                        _vm._v("Language:"),
                      ]),
                      _c("v-autocomplete", {
                        attrs: {
                          placeholder: "Default",
                          clearable: "",
                          items: _vm.languages,
                          "item-text": "lang",
                          "item-value": "code",
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.replaySettings.lang,
                          callback: function ($$v) {
                            _vm.$set(_vm.replaySettings, "lang", $$v)
                          },
                          expression: "replaySettings.lang",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.linkOptions = false
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.linkOutside },
                    },
                    [_vm._v(" Open Replay ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }