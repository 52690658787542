<template>
	<!-- <v-card>
		<v-card-text> -->
	<span>
		<KeyValueCard2
			:item="detail"
			:cols="filteredRows"
			:title="'Round detail'"
			:loading="false"
			color="secondary">
			<template #heading>
				<CardHeading title="Round Detail">
					<v-btn
						fab
						small
						class="elevation-0"
						@click="linkOptions = true"
						color="primary darken-1">
						<v-icon>mdi-cog-play</v-icon>
					</v-btn>
				</CardHeading>
			</template>
			<template #[`item.device.value`]="{ item }">
				<v-icon v-if="item.device === 0">mdi-projector-screen-outline</v-icon>
				<v-icon v-else-if="item.device === 1">mdi-monitor</v-icon>
				<v-icon v-else-if="item.device === 2">mdi-cellphone</v-icon>
				<v-icon
					v-else-if="item.device === 3"
					large>
					mdi-tablet
				</v-icon>
				<span v-else>unknown</span>
			</template>
			<template #[`item.bet.value`]="{ item }">
				{{ item.bet | Number({ currency }) }}
			</template>
			<template #[`item.win.value`]="{ item }">
				{{ item.win | Number({ currency }) }}
			</template>
			<template #[`item.netto.value`]="{ item }">
				{{ item.netto | Number({ currency }) }}
			</template>
			<template #[`item.finished.value`]="{ item }">
				<v-icon>{{ item.finished ? "mdi-check" : "mdi-close" }}</v-icon>
			</template>
			<template #[`item.start_time.value`]="{ item }">
				{{ item.start_time | Date }}
			</template>
			<template #[`item._orientation.value`]="{ item }">
				<v-icon v-if="item._orientation === 1">
					mdi-cellphone mdi-rotate-90
				</v-icon>
				<v-icon v-else>mdi-cellphone</v-icon>
			</template>
			<template #[`item.flags.value`]="{ item }">
				<template v-for="flag of item.flags">
					<v-tooltip
						bottom
						v-if="ROUND_FLAGS[flag]"
						:key="flag">
						<template #activator="{ on, attrs }">
							<v-avatar
								size="30"
								v-on="on"
								v-bind="attrs"
								color="primary"
								class="mx-1">
								<v-icon
									v-if="ROUND_FLAGS[flag].icon"
									class="font-weight-bold kajot-anti-text--text">
									{{ ROUND_FLAGS[flag].icon }}
								</v-icon>
								<span
									v-else
									class="font-weight-bold kajot-anti-text--text">
									{{ ROUND_FLAGS[flag].title }}
								</span>
							</v-avatar>
						</template>
						<span>{{ ROUND_FLAGS[flag].tooltip }}</span>
					</v-tooltip>
				</template>
			</template>
			<template #actions>
				<v-btn
					text
					@click="$emit('close')">
					Close
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					text
					@click="$emit('openDetail')"
					color="primary darken-1">
					Open Round Detail
				</v-btn>
			</template>
		</KeyValueCard2>
		<v-dialog
			width="600"
			v-model="linkOptions">
			<v-card>
				<v-card-title class="text-h5 primary">Replay Settings</v-card-title>

				<v-card-text class="py-2">
					<div class="mx-0 my-5 d-flex flex-row align-center">
						<span class="mr-4">Custom:</span>
						<v-switch
							hide-details
							class="mt-n1"
							inset
							v-model="replaySettings.advanced.value"></v-switch>
					</div>
					<template v-if="!replaySettings.advanced.value">
						<div class="d-flex flex-row justify-space-between align-center">
							<div>Device:</div>
							<v-radio-group
								hide-details
								class="mt-0"
								row
								v-model="_device">
								<v-radio
									hide-details
									class="my-0"
									:value="1"
									label="Desktop"
									on-icon="mdi-monitor"
									off-icon="mdi-monitor"></v-radio>
								<v-radio
									hide-details
									class="my-0"
									:value="2"
									on-icon="mdi-cellphone"
									label="Mobile"
									off-icon="mdi-cellphone"></v-radio>
								<v-radio
									hide-details
									class="my-0"
									:value="3"
									label="Tablet"
									on-icon="mdi-tablet"
									off-icon="mdi-tablet"></v-radio>
							</v-radio-group>
						</div>
						<div class="d-flex flex-row justify-space-between align-center">
							<div>Orientation:</div>
							<v-radio-group
								row
								v-model="replaySettings.orientation">
								<v-radio
									:value="1"
									label="Landscape"
									on-icon="mdi-arrow-expand-horizontal"
									off-icon="mdi-arrow-expand-horizontal"></v-radio>
								<v-radio
									v-if="replaySettings.device !== 1"
									:value="2"
									label="Portrait"
									on-icon="mdi-arrow-expand-vertical"
									off-icon="mdi-arrow-expand-vertical"></v-radio>
							</v-radio-group>
						</div>
					</template>

					<template v-else>
						<div
							class="mb-4 d-flex flex-row justify-space-between align-center">
							<div>Resolution:</div>
							<v-text-field
								outlined
								class="mx-2"
								dense
								type="number"
								v-model.number="replaySettings.advanced.width"
								hide-details
								label="Width"></v-text-field>
							<v-text-field
								outlined
								dense
								type="number"
								v-model.number="replaySettings.advanced.height"
								class="mx-2"
								hide-details
								label="Height"></v-text-field>
						</div>
						<div class="d-flex flex-row justify-space-between align-center">
							<div>Mode:</div>
							<v-radio-group
								row
								hide-details
								class="mt-0 mb-3"
								v-model="replaySettings.advanced.device">
								<v-radio
									hide-details
									class="mt-0"
									:value="1"
									label="Desktop"
									on-icon="mdi-monitor"
									off-icon="mdi-monitor"></v-radio>
								<v-radio
									hide-details
									class="mt-0"
									:value="2"
									on-icon="mdi-cellphone"
									label="Mobile"
									off-icon="mdi-cellphone"></v-radio>
							</v-radio-group>
						</div>
					</template>

					<div class="d-flex flex-row align-center">
						<span class="mr-3">Language:</span>
						<v-autocomplete
							placeholder="Default"
							clearable
							v-model="replaySettings.lang"
							:items="languages"
							item-text="lang"
							item-value="code"
							hide-details
							dense
							outlined></v-autocomplete>
					</div>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn
						color="error"
						text
						@click="linkOptions = false">
						Close
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="linkOutside">
						Open Replay
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
	<!-- </v-card-text>
		<v-card-actions>
			
		</v-card-actions>
	</v-card> -->
</template>
<script>
import KeyValueCard2 from "../../components/shared/keyValueCard2.vue";
import { mapGetters } from "vuex";
import { ROUND_FLAGS } from "../../constants/constants";
import { Round } from "../../constants/converters/round";
import CardHeading from "../shared/CardHeading.vue";
export default {
	components: {
		KeyValueCard2,
		CardHeading,
	},
	props: {
		item: Object,
		color: { type: String, default: "primary" },
		device: Number,
		currency: String,
	},
	computed: {
		...mapGetters(["currentCasino"]),
		filteredRows() {
			return this.rows.map((row) =>
				row.filter((item) => !item.hide || !item.hide())
			);
		},
		_device: {
			get() {
				return this.replaySettings.device;
			},
			set(val) {
				this.replaySettings.device = val;
				if (val === 1) {
					this.replaySettings.orientation = 1;
				}
			},
		},
	},
	methods: {
		linkOutside() {
			let extras;
			if (this.replaySettings.advanced.value) {
				extras = {
					width: this.replaySettings.advanced.width,
					height: this.replaySettings.advanced.height,
					device: this.replaySettings.advanced.device,
				};
			}
			const replayLink = Round.replayLink(
				{ ...this.detail, orientation: this.replaySettings.orientation },
				this.currentCasino,
				this.replaySettings.device,
				extras,
				this.replaySettings.lang
			);

			window.open(...replayLink);
		},
	},
	data() {
		return {
			replaySettings: {
				device: 1,
				orientation: 1,
				lang: undefined,
				advanced: { value: false, width: 1000, height: 800, device: 1 },
			},
			linkOptions: false,
			ROUND_FLAGS,
			detail: {
				...this.item,
				device: this.device,
			},
			languages: [
				{ lang: "English", code: "en" },
				{ lang: "Bulgarian", code: "bg" },
				{ lang: "Croatian", code: "hr" },
				{ lang: "Czech", code: "cs" },
				{ lang: "Dutch", code: "nl" },
				{ lang: "French", code: "fr" },
				{ lang: "German", code: "de" },
				{ lang: "Greek", code: "el" },
				{ lang: "Italian", code: "it" },
				{ lang: "Polish", code: "pl" },
				{ lang: "Portuguese", code: "pt" },
				{ lang: "Romanian", code: "ro" },
				{ lang: "Slovak", code: "sk" },
				{ lang: "Spanish", code: "es" },
				{ lang: "Turkish", code: "tr" },
			],
			rows: [
				[
					{ label: "Session ID", key: "session_mongo_id" },
					{ label: "Round ID", key: "round_id" },
				],
				[
					{
						label: "Internal Round",
						key: "internal_round",
						hide: () => !this.detail.internal_round,
					},
					{
						label: "External Round",
						key: "external_round",
						hide: () => !this.detail.external_round,
					},
				],
				[
					{
						label: "Internal Bet",
						key: "internal_bet",
						hide: () => !this.detail.internal_bet,
					},
					{
						label: "External Bet",
						key: "external_bet",
						hide: () => !this.detail.external_bet,
					},
				],
				[
					{
						label: "Internal Win",
						key: "internal_win",
						hide: () => !this.detail.internal_win,
					},
					{
						label: "External Win",
						key: "external_win",
						hide: () => !this.detail.external_win,
					},
				],
				[
					{ label: "Start Time", key: "start_time" },
					{ label: "Finished", key: "finished" },
				],
				[
					{ label: "Currency", key: "currency" },
					{ label: "Bet", key: "bet" },
					{ label: "Win", key: "win" },
					{ label: "Netto", key: "netto" },
				],
				[
					{ label: "Device", key: "device", hide: () => !this.detail.device },
					{ label: "Orientation", key: "_orientation" },
					{ label: "Hand", key: "_hand" },
				],
				[
					{
						label: "Flags",
						key: "flags",
						hide: () => !this.detail.flags || this.detail.flags?.length === 0,
					},
				],
			],
		};
	},
};
</script>
